import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import farmEquipmentsAction from '../../Redux/farmEquipments/action';
import cartActions from "../../Redux/cart/actions";
import commonActions from "../../Redux/common/actions";
import { MdOutlineArrowForwardIos, MdOutlineCurrencyRupee, MdOutlineArrowBackIosNew } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { EncryptFunction } from "../../utils/cryptoFunction";
import { priceRoundOff } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";

function FarmEquipments() {

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { FarmEquipments } = useSelector((state) => state.FarmEquipmentsReducer);

  const { currentLanguage } = useSelector(state => state.commonReducer);

  const { userAddress } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch({ type: farmEquipmentsAction.GET_FARM_EQUIPMENTS });
    //eslint-disable-next-line
  }, []);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
        },
      },
    ],
    prevArrow: <MdOutlineArrowBackIosNew />,
    nextArrow: <MdOutlineArrowForwardIos />,
  };

  const filteredFarmEquipments = FarmEquipments?.data?.filter(product => product.cattle_type === 'Market');

  const convertToKilograms = (grams) => {
    if (grams >= 1000) {
      return (grams / 1000).toFixed(2) + 'kg';
    } else {
      return grams + 'gm';
    }
  };

  const handleViewMore = () => {
    LogEvent('Home_FarmEquipments_ViewAll')
    navigate(`/products?category=market`);
  }

  const handleViewDetails = (e, id, value) => {
    e.preventDefault();
    LogEvent('Home_FarmEquipments_Products')
    const encrypt = EncryptFunction(id);
    if (encrypt) {
      navigate(`/product-details?id=${encrypt}`, { state: value });
    }
  }

  const handleAddToCart = (e, value) => {
    e.preventDefault();
    LogEvent('Home_FarmEquipments_AddToCart')
    let discountValue = 0;
    if (value?.discount_status === 1 && value.discount_amount > 0) {
      if (value?.discount_type === 1) {
        discountValue = (value?.amount * value?.discount_amount) / 100;
      } else {
        discountValue = value?.discount_amount;
      }
    }
    dispatch({
      type: cartActions.CURRENT_ADDING_CART_PRODUCT,
      payload: {
        order_items: [
          {
            product_id: value?.id,
            rate: value?.amount,
            quantity: 1,
            name: value?.medicine_name,
            discount: priceRoundOff(discountValue),
            zoho_item_id: 0,
            gst: value?.gst,
            coupon_discount: 0,
          },
        ],
        //   data:{
        //     "order_items": [
        //         {
        //             "product_id": value?.id,
        //             "rate": value?.amount,
        //             "quantity": 1,
        //             "name": value?.language?.find(lang => lang?.language_code === currentLanguage)?.medicine_name,
        //             // "name": value?.medicine_name,
        //             "discount": priceRoundOff(discountValue),
        //             "zoho_item_id": 0,
        //             "gst": value?.gst,
        //             "coupon_discount": 0
        //         }
        //     ]
        // },
        // cattle:"market"

      },
    });
    if (localStorage.getItem("token")) {
      if (userAddress?.length === 0) {
        sessionStorage.setItem("addtocart", "Address");
        dispatch({
          type: commonActions.SET_SHOW_USER_ADDRESS_MODAL,
          payload: true,
        });
      } else {
        dispatch({ type: cartActions.ADD_PRODUCT_TO_CART });
      }
    } else {
      sessionStorage.setItem("addtocart", "Login");
      dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: true });
    }
  }

  return (
    <div className="container-fluid farm-equipments" style={{ backgroundColor: 'white' }}>
      <div className="container">
        <div className="home_page_heading_div_cls">
          {t("Farm Equipments")}
        </div>
        <div className="mt-3">
          <Slider {...settings} className="slider-arrow-custom-style-for-related-products">
            {filteredFarmEquipments?.slice(0, 10)?.map((product, index) => (
              <div key={index} className="farm-equi-card cursor-pointer">
                <div>
                  <img
                    src={product.image_url}
                    className="home-farmequip-img-cls"
                    alt={`Product ${index + 1}`}
                    onClick={() => handleViewDetails(product?.id)}
                  />
                  <div className="related_product_card_body">
                    <div className="related-product-name cursor-pointer" onClick={(e) => handleViewDetails(e, product?.id, product)}>
                      {product?.language?.find(lang => lang?.language_code === currentLanguage)?.medicine_name || product.medicine_name}
                    </div>
                    <div className="related_product_pack_weight_div">{t("Weight")} : {convertToKilograms(product.package_weight_in_gram)}</div>
                    <div className="mb-3">
                      {product?.discount_status === 1 ?
                        <div>
                          <div className="related_product_card_price_Del">
                            <div>{t("MRP")}</div>
                            <del><MdOutlineCurrencyRupee />{product.amount}</del>
                            {(product.discount_amount > 0) && (
                              <span className="related_product_dis_value"
                              >
                                <>
                                  {product?.discount_type === 1 ?
                                    `${product.discount_amount}% off` :
                                    `- ₹${product.discount_amount}`
                                  }
                                </>
                              </span>
                            )}
                          </div>
                          {(product?.discount_type === 1) ?
                            <span className="related-product-final-price-span mt-2"> <MdOutlineCurrencyRupee />{priceRoundOff(product?.amount - (product?.amount * product?.discount_amount) / 100)}</span> :
                            <span className="related-product-final-price-span mt-2"><MdOutlineCurrencyRupee />{priceRoundOff(product?.amount - product?.discount_amount)}</span>
                          }
                        </div> :
                        <div className="related-product-final-price-span"><MdOutlineCurrencyRupee />{product?.amount}</div>
                      }
                    </div>
                    <div className="d-flex justify-content-center" >
                      <Button
                        className="relatedproduct-viewproduct-btn"
                        onClick={(e) => handleAddToCart(e, product)}
                      >
                        {t("Add To Cart")}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            <div onClick={handleViewMore}>
              <div className="container farm-equipments-section-view-all">
                <h5 className="farm-equipment-view-all-btn">{t("View All")} <IoIosArrowForward /></h5>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </div>

  );
}

export default FarmEquipments;

import React from "react";
import medicinesection from '../../../src/assets/images/new_home_page/Frame-001.jpg';
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from "../../utils/cryptoFunction";
import { LogEvent } from "../../utils/firebase";
import { useTranslation } from 'react-i18next';


function MedicineSection() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const textStyle = {
    fontSize: currentLang === 'tm' ? '14px' : '16px',
    lineHeight: currentLang === 'tm' ? '1.5' : '1.6',
  };

  return (
    <div>
      <img src={medicinesection} className="img-responsive_medicine"></img>
      <div className="center-card-body d-flex align-items-center">
        <div className="position-relative w-100">
          <div className="new_home_page-content-medicine_section">
            <h5 className="card-title fw-semi-bold center-card-title new_home_page_custom-color" style={textStyle}>
              {t("Expert vet consultation and")}
              <br />
              {t("guidance for healthy farming")}
            </h5>          <div >
              <button className='new_home_page_Team-center-card-button d-flex justify-content-center'>
                <div className="Team-Icon">
                  <FaPhoneAlt alt="Custom Icon" style={{ color: '#232c63' }} />
                </div>
                <span onClick={() => {
                  const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                  LogEvent('Floating_Call_Button_Clicked');
                  window.location.href = `tel:${phoneNumber}`;
                }}>{t('Call Doctor')}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MedicineSection;


import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ImageViewer({ showModal, onCancel, images, productName }) {
    const media = images?.[0]; // Get the first media item (image or video)

    return (
        <Modal show={showModal} onHide={onCancel} backdrop="static" size="md">
            <Modal.Header closeButton>
                <Modal.Title>{productName}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {media ? (
                    media.type === 'video' ? (
                        <video controls style={{ maxWidth: '100%', maxHeight: '360px' }}>
                            <source src={media.url} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <img src={media.url} alt="First Image" style={{ maxWidth: '100%', maxHeight: '360px' }} />
                    )
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onCancel}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ImageViewer;

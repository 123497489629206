import React from 'react';
import treatmentcardimage1 from "../../../assets/images/bannercardimages/Doctorbanner.webp"
import { Carousel, Container } from 'react-bootstrap';
import { IoCall } from "react-icons/io5";
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from '../../../utils/cryptoFunction';
import { LogEvent } from '../../../utils/firebase';
const TreatmentCard = () => {

    const { t } = useTranslation();
    return (
        <div className="background-custom-color">
            <Container>
                <Carousel controls={false} style={{ background: 'none' }} interval={3000}>
                    <Carousel.Item>
                        <div className="container">
                            <div className="treatment-center-card bg-light-subtle mt-4">
                                <div className="center-card-body d-flex align-items-center">
                                    <div className="position-relative w-100">
                                        <div className="positioned-content">
                                            <h5 className="card-title fw-semi-bold center-card-title custom-color">{t("YourFarm Vision & Mission")}</h5>
                                            <p className="white-text responsive-text mt-2">{t(' Empowering dairy farmers with accessible and affordable animal healthcare and nutrition')}</p>
                                            <div >
                                                <button className='Our_Team-center-card-button d-flex justify-content-center'>
                                                    <div className="Team-Icon">
                                                        <FaPhoneAlt alt="Custom Icon" />
                                                    </div>
                                                    <span onClick={() => {
                                                        const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                        LogEvent('Floating_Call_Button_Clicked');
                                                        window.location.href = `tel:${phoneNumber}`;
                                                    }}>{t('Call Doctor')}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <img src={treatmentcardimage1} className="banner-img-fluid" alt="Veterinary Service Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="container">
                            <div className="treatment-center-card bg-light-subtle mt-4">
                                <div className="center-card-body d-flex align-items-center">
                                    <div className="position-relative w-100">
                                        <div className="positioned-content">
                                            <h5 className="card-title fw-semi-bold center-card-title custom-color">{t("YourFarm Vision & Mission")}</h5>
                                            <p className="white-text responsive-text mt-2">{t(' Empowering dairy farmers with accessible and affordable animal healthcare and nutrition')}</p>
                                            <div >
                                                <button className='Our_Team-center-card-button d-flex justify-content-center'>
                                                    <div className="Team-Icon">
                                                        <FaPhoneAlt alt="Custom Icon" />
                                                    </div>
                                                    <span onClick={() => {
                                                        const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                        LogEvent('Floating_Call_Button_Clicked');
                                                        window.location.href = `tel:${phoneNumber}`;
                                                    }}>{t('Call Doctor')}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <img src={treatmentcardimage1} className="banner-img-fluid" alt="Veterinary Service Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="container">
                            <div className="treatment-center-card bg-light-subtle mt-4">
                                <div className="center-card-body d-flex align-items-center">
                                    <div className="position-relative w-100">
                                        <div className="positioned-content">
                                            <h5 className="card-title fw-semi-bold center-card-title custom-color">{t("YourFarm Vision & Mission")}</h5>
                                            <p className="white-text responsive-text mt-2">{t(' Empowering dairy farmers with accessible and affordable animal healthcare and nutrition')}</p>
                                            <div >
                                                <button className='Our_Team-center-card-button d-flex justify-content-center'>
                                                    <div className="Team-Icon">
                                                        <FaPhoneAlt alt="Custom Icon" />
                                                    </div>
                                                    <span onClick={() => {
                                                        const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                                                        LogEvent('Floating_Call_Button_Clicked');
                                                        window.location.href = `tel:${phoneNumber}`;
                                                    }}>{t('Call Doctor')}</span>
                                                </button>
                                            </div>
                                        </div>
                                        <img src={treatmentcardimage1} className="banner-img-fluid" alt="Veterinary Service Image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </Container>
        </div>
    )
}

export default TreatmentCard

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import noprofile from '../../assets/images/noprofile.png';
import { FaLocationDot } from "react-icons/fa6";
import moment from "moment-timezone";
import { Rating } from 'react-simple-star-rating';
import { useTranslation } from 'react-i18next';
import { PiPencilSimpleFill } from "react-icons/pi";
import { Col, Row, Spinner } from "react-bootstrap";
import noimg from '../../assets/images/noImage.jpg';
import marketActions from '../../Redux/marketPlace/action';
import { PiCoins, PiArrowsCounterClockwise, PiCameraBold } from 'react-icons/pi';
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import farmActions from '../../Redux/sellerProfile/action';
import userActions from "../../Redux/user/actions";
import commonActions from "../../Redux/common/actions";
import SellerSelectAddressModal from "../SellerProfile/SellerSelectAddressModal";
import { DecryptFunction } from "../../utils/cryptoFunction";
import SellerProfileUpdateModal from "../modal/sellerProfileUpdateModal";
import CattleDetailsModal from "../modal/cattleDetailsModal";
import Pagination from "../../common/pagination";
import Subscription from "./subscription";
import { AlphabetsValidation, handleEmailValidation } from "../../utils/validation";
import { LogEvent } from "../../utils/firebase";
import AnimalEditModal from "../modal/animaleditmodal";

export default function SellerProfile() {

    const { t } = useTranslation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { sellerprofile, listingdetails, getlistingstatus } = useSelector((state) => state.MarketPlaceReducer);

    const { farmList } = useSelector((state) => state.farmTypeReducer);

    const { userAddress } = useSelector(state => state.userReducer);

    const { Addressloglat, buttonLoader } = useSelector(state => state.commonReducer);

    const { userSubscriptions } = useSelector(state => state.subscriptionReducer);

    const { handleSubmit, register, reset, formState: { errors } } = useForm();

    const [activeTab, setActiveTab] = useState(0);
    const [fetchedData, setFetchedData] = useState([]);
    const [selleraddress, setSelleraddress] = useState();
    const [showAddressModal, setShowAddressModal] = useState(false);
    const [addressErr, setAddressErr] = useState(false);
    const [showSubscriptionPage, setShowSubscriptionPage] = useState({ show: false, data: null });
    const [logo, setLogo] = useState({ file: null, url: null });
    const [showSellerProfileUpdateModal, setShowSellerProfileUpdateModal] = useState(false);
    const [showCattleModal, setShowCattleModal] = useState({ isOpen: false, data: null });
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(6);
    const [currentPage, setCurrentPage] = useState(0);
    const [showAnimalAddModal, setShowAnimalAddModal] = useState(false);
    const [Animalid, setAnimalid] = useState(false);

    useEffect(() => {
        dispatch({ type: marketActions.GET_LISTING_STATUS });
        dispatch({ type: farmActions.GET_FARM_TYPES });
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const user = DecryptFunction(localStorage.getItem("auth_user"));
        if (user) {
            dispatch({ type: userActions.GET_USER_ADDRESS, payload: { id: user } });
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        const mobile_no = DecryptFunction(localStorage?.getItem('auth_user_no'));
        const user_id = DecryptFunction(localStorage?.getItem('auth_user'));
        if (mobile_no && user_id) {
            reset({
                "mobile_no": mobile_no,
                "user_id": user_id
            });
        };
        //eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (userAddress?.length > 0) {
            if (userAddress?.find(val => val?.default_address === 1)) {
                setSelleraddress(userAddress?.find(val => val?.default_address === 1));
                dispatch({
                    type: commonActions.GET_ADDRESS_COORDINATES,
                    payload: userAddress?.find(val => val?.default_address === 1),
                });
            } else {
                setSelleraddress(userAddress[0]);
                dispatch({
                    type: commonActions.GET_ADDRESS_COORDINATES,
                    payload: userAddress[0],
                });
            }
        };
        //eslint-disable-next-line
    }, [userAddress]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = fetchedData?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(fetchedData?.length / itemsPerPage);
console.log("test-1",currentItems)
    const handlePageChange = (event) => {
        const newOffset = (event.selected * itemsPerPage) % fetchedData?.length;
        setItemOffset(newOffset);
        setCurrentPage(event?.selected);
        window.scrollTo({ top: 0 })
    };

    const handleShowAddressModal = () => {
        setShowAddressModal(true);
    };

    const handleAddress = (Address) => {
        setSelleraddress(Address);
        dispatch({
            type: commonActions.GET_ADDRESS_COORDINATES,
            payload: Address,
        });
        setShowAddressModal(false);
    };

    const onSubmit = (data) => {
        if (!selleraddress?.address_line1) {
            setAddressErr(true);
        } else {
            setAddressErr(false);
            const finalData = {
                ...data,
                latitude: Addressloglat?.Coordinates?.latitude,
                longitude: Addressloglat?.Coordinates?.longitude,
                // address: selleraddress
                address: {
                    address_line1: selleraddress?.address_line1,
                    address_line2: selleraddress?.address_line2,
                    address_line3: selleraddress?.address_line3,
                    city: selleraddress?.city,
                    state: selleraddress?.state,
                    country: selleraddress?.country,
                    pincode: selleraddress?.pincode,
                    other_address_details: selleraddress?.other_address_details
                }
            };
            if (logo?.file) {
                dispatch({
                    type: userActions.UPLOAD_IMAGE_TO_AWS,
                    payload: {
                        files: logo?.file, type: 'SellerRegistration',
                        data: { formData: finalData, page: "Seller_Profile", navigate: navigate }
                    }
                });
            } else {
                dispatch({
                    type: farmActions.CREATE_SELLER_USER,
                    payload: { data: finalData, page: "Seller_Profile", navigate: navigate },
                });
            }
        };
    };

    const handleImageChange = (e) => {
        if (e.target.files?.length > 0) {
            setLogo({ file: e.target.files[0], url: URL.createObjectURL(e.target.files[0]) });
        };
    };

    useEffect(() => {
        if (listingdetails?.code === 200) {
            setFetchedData(listingdetails?.data);
        };
    }, [listingdetails]);

    const handleIconClick = (id) => {
        LogEvent('Profile_Edit_Cattle_Clicked')
        // navigate( { state: id });
        setAnimalid(id); 
        setShowAnimalAddModal(true)
    };


    const handleActiveTab = (id) => {
        setActiveTab(id);
        if (id === 0) {
            setFetchedData(listingdetails?.data);
        } else {
            //eslint-disable-next-line
            setFetchedData(listingdetails?.data?.filter((val) => {
                if (val?.listing?.listing_status === id) {
                    return val;
                }
            }))
        }
    };

    const handleDisableSubscription = () => {
        setShowSubscriptionPage({ show: false, data: null });
    };

    const handleShowModal = (id) => {
        setShowCattleModal({ isOpen: true, data: id });
    };

    return (
        <div>
            {!showSubscriptionPage?.show ?
                <>
                    {sellerprofile?.data?.length > 0 ?
                        <>
                            <div className="marketplace_seller_edit_main_div">
                                {sellerprofile?.data?.length > 0 &&
                                    <>
                                        {sellerprofile?.data?.map((val, i) =>
                                            <div key={i} className="d-flex justify-content-between">
                                                <div className="d-flex align-items-center marketplace_seller_card_div">
                                                    <img src={val?.logo || noprofile} alt="" className="marketplace_seller_profile_img" />
                                                    <div className="marketplace_seller_profile_details">
                                                        <div className="marketplace-cattle-card-title mb-1">{val?.provider_name || 'N/A'}</div>
                                                        <div className="marketplace-cattle-card-place-view-profile mb-1"><FaLocationDot /> {val?.address?.city || "N/A"}</div>
                                                        <div className="marketplace-cattle-card-postedby-iew-profile mb-1">{t("Created On")}: {moment(val?.created_at).format('DD/MM/YYYY')}</div>
                                                        <Rating
                                                            initialValue={val?.average_rating}
                                                            className="marketplace-cattle-ratings"
                                                            readonly
                                                        />
                                                    </div>
                                                </div>
                                                <div className="marketplace-seller-edit-icon cursor-pointer" onClick={() => setShowSellerProfileUpdateModal(true)}>
                                                    <PiPencilSimpleFill className="marketplace-seller-pencil-icon-cls" /> {t("Edit")}
                                                </div>
                                            </div>
                                        )}
                                    </>
                                }
                            </div>
                            <div className="mb-4">
                                <div className="d-flex marketplace_seller_cattle_tabs">
                                    <div
                                        className={`marketplace_seller_cattle_tab ${activeTab === 0 && 'marketplace_seller_cattle_tab_active'}`}
                                        onClick={() => handleActiveTab(0)}
                                    >
                                        {t("All")}
                                    </div>
                                    {getlistingstatus?.code === 200 &&
                                        <>
                                            {getlistingstatus?.data?.filter((val) => {
                                                if (val?.title !== 'On Hold' && val?.title !== 'Delisted') {
                                                    return val
                                                }
                                            })?.map((val, i) =>
                                                <div
                                                    key={i}
                                                    className={`marketplace_seller_cattle_tab ${activeTab === val?.id && 'marketplace_seller_cattle_tab_active'}`}
                                                    onClick={() => handleActiveTab(val?.id)}
                                                >
                                                    {t(val?.title)}
                                                </div>
                                            )}
                                        </>
                                    }
                                </div>
                                {fetchedData?.length > 0 ?
                                    <Row className="mt-4 row-padding-remover">
                                        {currentItems?.map((val, i) =>
 
                                            <Col xl={4} lg={6} md={12} xs={12} key={i} className="mb-4">
                                                <div className="marketplace_seller_catte_list_col_card">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="marketplace_seller_catte_list_col_title one_line_ellipsis mb-3 cursor-pointer" onClick={() => handleShowModal(val?.listing?.id)}>{val?.listing?.title}</div>
                                                        {val?.listing?.subscription_id === 0 ?
                                                            <div className="seller_profile_paynow_btn cursor-pointer" onClick={() => {
                                                                LogEvent('Profile_Cattle_PayNow_Clicked')
                                                                setShowSubscriptionPage({ show: true, data: { id: val?.listing?.id, value: val } })
                                                            }}>
                                                                <PiCoins /> {t("Pay now")}
                                                            </div> :
                                                            (val?.listing?.subscription_id > 0 &&
                                                                (userSubscriptions?.find(s => s.id === val?.listing?.subscription_id)?.expires_at && (moment().isSame(userSubscriptions?.find(s => s.id === val?.listing?.subscription_id)?.expires_at, 'day') || !moment().isAfter(userSubscriptions?.find(s => s.id === val?.listing?.subscription_id)?.expires_at, 'day')))) ?
                                                                <div className="seller_profile_paynow_btn cursor-pointer" onClick={() => {
                                                                    LogEvent('Profile_Cattle_PayNow_Clicked')
                                                                    setShowSubscriptionPage({ show: true, data: { id: val?.listing?.id, value: val } })
                                                                }}><PiArrowsCounterClockwise /> {t("Renew")}</div>
                                                                : <></>
                                                        }
                                                    </div>
                                                    <div className="marketplace_seller_catte_list_col_rate mb-3">₹{val?.listing?.rate}</div>
                                                    <img src={val?.cattle?.image_front_url || noimg} alt="" className="marketplace_seller_cattle_img mb-2" />
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className={`marketplace_seller_cattle_${(getlistingstatus?.data?.find((status) => status?.id === val?.listing?.listing_status)?.title)?.replace(/ /g, '')}_btn`}>
                                                            {getlistingstatus?.data?.find((status) => status?.id === val?.listing?.listing_status)?.title}
                                                        </div>
                                                        {val?.listing?.listing_status === 1 &&
                                                            // <div className="marketplace-seller-cattle-edit-icon cursor-pointer" onClick={() => setShowAnimalAddModal(true)}>
                                                            <div className="marketplace-seller-cattle-edit-icon cursor-pointer" onClick={() => handleIconClick(val?.listing?.id)}>
                                                                {/* <div className="marketplace-seller-cattle-edit-icon cursor-pointer" onClick={() => handleIconClick(val?.listing?.id)}> */}
                                                                <PiPencilSimpleFill className="marketplace-seller-pencil-icon-cls" /> {t("Edit")}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                        )}
                                        {pageCount > 1 && currentItems?.length > 1 &&
                                            <div className="mt-4">
                                                <Pagination pageCount={pageCount} onPageChange={handlePageChange} currentPage={currentPage} />
                                            </div>
                                        }
                                    </Row> :
                                    <div className="no-data-found-div-cls">{t("No Data Found")}</div>
                                }
                            </div>
                        </> :
                        <div>
                            <div className="post_animal_edit_heading">
                                {t('Become a Seller')}
                            </div>
                            <Row className="row-padding-remover">
                                <Col xl={9} className="pt-1 d-flex own-seller-profile-img-col d-flex justify-content-center">
                                    <img src={logo?.url || noprofile} alt="" className="own-seller-profile-img-edit-mode" />
                                </Col>
                                <Col xl={9} className="pb-4 d-flex justify-content-center">
                                    <div>
                                        {/* <small>Empower your business journey – Join us as a seller and unlock new opportunities. Seamlessly connect with customers and grow your brand on our platform.</small> */}
                                        <div>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                style={{ display: 'none' }}
                                                id="contained-button-file"
                                                onChange={handleImageChange}
                                            />
                                            <label htmlFor="contained-button-file">
                                                <div className="own-seller-profile-upload-btn">
                                                    <PiCameraBold className="seller-upload-img-camera-icon" /> {t("Upload Image")}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </Col>
                                <Col xl={9} className="pb-4">
                                    <div className="form-group seller_profile_page_register_form_grup">
                                        <label className="form-label seller_profile_form_label">{t("Name")}</label>
                                        <input
                                            className="form-control seller_profile_form_input"
                                            {...register('provider_name', {
                                                pattern: {
                                                    value: /^[a-zA-Z ]*$/,
                                                    message: "Name should only contain alphabets",
                                                }
                                            })}
                                            onInput={AlphabetsValidation}
                                        />
                                    </div>
                                </Col>
                                <Col xl={9} className="pb-4">
                                    <div className="form-group seller_profile_page_register_form_grup">
                                        <label className="form-label seller_profile_form_label">{t("Farm Type")} <span className="fw-bold text-danger">*</span></label>
                                        <select
                                            className="form-control seller_profile_form_input ms-0"
                                            {...register('farm_type_id', {
                                                required: "*required"
                                            })}
                                            defaultValue={""}
                                        >
                                            <option value={""} disabled>--Select--</option>
                                            {farmList?.map((farm, i) =>
                                                <option value={farm?.id} key={i}>{farm?.title}</option>
                                            )}
                                        </select>
                                        {errors?.farm_type_id &&
                                            <small className="text-danger">{errors?.farm_type_id?.message}</small>
                                        }
                                    </div>
                                </Col>
                                <Col xl={9} className="pb-4">
                                    <div className="form-group seller_profile_page_register_form_grup">
                                        <label className="form-label seller_profile_form_label">{t("Email")}</label>
                                        <input
                                            className="form-control seller_profile_form_input"
                                            {...register('email', {
                                                validate: handleEmailValidation
                                            })}
                                        />
                                    </div>
                                </Col>
                                <Col xl={9} className="pb-4">
                                    <div className="seller_profile_page_register_form_grup">
                                        <label className="form-label seller_profile_form_label">{t("Address")} <span className="fw-bold text-danger">*</span></label>
                                        <div>
                                            {selleraddress &&
                                                <div className="address-book-address-seller-profile-register mb-2">
                                                    <div>{`${selleraddress?.address_line1}, ${selleraddress?.address_line2}, ${selleraddress?.address_line3},`}</div>
                                                    <div>{`${selleraddress?.city} - ${selleraddress?.pincode}.`}</div>
                                                    <div>{`${selleraddress?.state}, ${selleraddress?.country}`}</div>
                                                </div>
                                            }
                                            <div className="user-rofile-select-address" onClick={handleShowAddressModal}><PiPencilSimpleFill /> {selleraddress ? t("Change Address") : t("Select Address")}</div>
                                        </div>
                                        {addressErr &&
                                            <p className="text-danger my-2">Address is required</p>
                                        }
                                    </div>
                                </Col>
                                <Col xl={9} className="pt-2">
                                    <div className="seller_profile_page_register_form_grup text-center">
                                        <button
                                            className="profile-save-btn-cls mb-4 me-2"
                                            type="submit"
                                            onClick={handleSubmit(onSubmit)}
                                        >
                                            {buttonLoader ? <Spinner variant="light" size="sm" /> : t("Save Changes")}
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    }
                </> :
                <Subscription data={showSubscriptionPage?.data} handleDisableSubscription={handleDisableSubscription} />
            }
            <SellerSelectAddressModal
                showModal={showAddressModal}
                closeModal={() => setShowAddressModal(false)}
                handleAddress={handleAddress}
            />
            <SellerProfileUpdateModal
                showModal={showSellerProfileUpdateModal}
                closeModal={() => setShowSellerProfileUpdateModal(false)}
            />
            <CattleDetailsModal
                showModal={showCattleModal?.isOpen}
                closeModal={() => setShowCattleModal({ isOpen: false, data: null })}
                cattleId={showCattleModal?.data}
                page={'edit'}
            />
            <AnimalEditModal
                showModal={showAnimalAddModal}
                closeModal={() => setShowAnimalAddModal(false)}
                animalId={Animalid}
            />
        </div>
    )
};
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import LoginAction from "../../Redux/Home/action";
import commonActions from "../../Redux/common/actions";
import { MobileNumberValidation, MobileNumberFirstNumberValidation } from "../../utils/validation";
import { useTranslation } from 'react-i18next';
import { LogEvent } from "../../utils/firebase";

function LoginModal() {
  const { t, i18n } = useTranslation();
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [loading] = useState(false);
  const [isOtpComplete, setIsOtpComplete] = useState(false);
  const [resendCountdown, setResendCountdown] = useState(0);
  const [verificationSuccess] = useState(false);

  const otpBoxReference = useRef([]);

  const dispatch = useDispatch();

  const { showLoginModal, buttonLoader } = useSelector(
    (state) => state.commonReducer
  );
  const currentLang = i18n.language;

  const textStyle = {
    fontSize: currentLang === 'tm' ? '13px' : '16px',
    lineHeight: currentLang === 'tm' ? '1.5' : '1.6', 
  };

  const { homeResponse } = useSelector((state) => state.AuthReducer);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();



  const handleSendOtp = (data) => {
    dispatch({
      type: LoginAction.SEND_OTP,
      payload: {
        mobile_no: `+91${data?.MobileNumber}`,
      },
    });
    setResendCountdown(60)
  };

  useEffect(() => {
    let timer;
    if (resendCountdown > 0) {
      timer = setTimeout(() => setResendCountdown(resendCountdown - 1), 1000);
    }
    return () => clearTimeout(timer);
  }, [resendCountdown]);

  const handleVerifyOtp = () => {
    LogEvent('Login_Succeed');
    const otp = otpValues.join("");
    dispatch({
      type: LoginAction.VERIFY_OTP,
      payload: {
        mobile_no: homeResponse?.data?.mobile_no,
        otp,
      },
    });
  };

  const handleOtpChange = (index, value) => {
    const updatedOtpValues = [...otpValues];
    updatedOtpValues[index] = value;
    setOtpValues(updatedOtpValues);

    const isComplete = updatedOtpValues.every((val) => val !== "");
    setIsOtpComplete(isComplete);

    if (value !== "" && index < otpValues.length - 1) {
      otpBoxReference.current[index + 1].focus()
    }

  };
  const handleResendOtp = () => {
    setOtpValues(["", "", "", "", "", ""]);
    setIsOtpComplete(false);
    setResendCountdown(60)
    dispatch({
      type: LoginAction.SEND_OTP,
      payload: {
        mobile_no: `${homeResponse?.data?.mobile_no}`,
      },
    });
  };

  const handleCloseModal = () => {
    sessionStorage.setItem("loginModal", "false");
    dispatch({ type: commonActions.SET_SHOW_LOGIN_MODAL, payload: false });
  };

  function handleBackspaceAndEnter(e, index) {
    if (e.key === "Backspace" && !e.target.value && index > 0) {
      otpBoxReference.current[index - 1].focus()
    }
    if (e.key === "Enter" && e.target.value && index < 6 - 1) {
      otpBoxReference.current[index + 1].focus()
    }
  }

  const handleFocus = (event) => event.target.select();

  return (
    <>
      <Modal
        dialogClassName="login_modal"
        show={showLoginModal}
        onHide={handleCloseModal}
        centered
        size="md"
      >
        <div className="modal_close_icons" onClick={handleCloseModal}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 24 24"
            height="1em"
            width="1em"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
          </svg>
        </div>
        <Row className="row-padding-remover">
          {/* <Col md={5} xs={12} sm={12} className="login-modal-row">
            <img
              src={require("../../assets/images/lognModalImg.jpg")}
              alt=""
              className="login-modal-img"
            />
          </Col> */}
          <Col md={12} xs={12} sm={12} className="my-auto">
            <div className="opt-col2-div-cls">
              {/* <div className="login_forms"> */}
              <div className="login_title">
                <img
                  src={require("../../assets/images/Logo1.jpg")}
                  alt=""
                  className="login-modal-logo"
                />
                <h4 className="login-modal-h5">{t("Login")}</h4>
              </div>
              <Form>
                <Form.Group controlId="formMobile" style={{ width: "66%", margin: "auto" }}>
                  <Form.Label style={{ fontSize: "16px" }}>{t("Mobile Number")}</Form.Label>
                  <Form.Control
                    type="tel"
                    onWheel={(e) => e.target.blur()}
                    placeholder={t("Enter mobile number")}
                    className="login-modal-input"
                    style={{ fontSize: "16px", textAlign: "center" }}
                    {...register("MobileNumber", {
                      required: "Enter your mobile number",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Please enter a 10-digit phone number"
                      },
                      validate: MobileNumberFirstNumberValidation
                    })}
                    onInput={MobileNumberValidation}
                    maxLength={10}
                    disabled={homeResponse?.code === 200 ? true : false}
                  />

                  {errors?.MobileNumber?.message && (
                    <Form.Text className="text-danger" style={{ fontSize: "14px" }}>
                      {errors?.MobileNumber?.message}
                    </Form.Text>
                  )}
                </Form.Group>

                {!homeResponse?.code && (
                  <div className="d-flex justify-content-center login-modal-btn">
                    <Button
                      variant="primary"
                      onClick={handleSubmit(handleSendOtp)}
                      disabled={buttonLoader}
                      className="login-modal-submit-btn"
                      type="submit"
                      style={{...textStyle, fontSize: "16px", width: "66%", whiteSpace: 'nowrap' }}                    >
                      {buttonLoader ? `${t("Sending OTP")}...` : t("Send OTP")}
                    </Button>
                  </div>
                )}

                {(homeResponse?.code && !verificationSuccess) && (
                  <div className="d-flex justify-content-center mt-3">
                    {otpValues.map((value, index) => (
                      <div key={index} className="mx-1 login-otp-form">
                        <input
                          id={`otpInput-${index}`}
                          type="text"
                          value={homeResponse?.code ? value : ""}
                          onChange={(e) =>
                            handleOtpChange(index, e.target.value)
                          }
                          className={`text-center otp-tet-field-input-cls ${!isOtpComplete && value === ""
                            ? "border border-danger"
                            : ""
                            }`}
                          // style={{ fontSize: "19px", width: "45px", height: "50px" }}
                          maxLength={1}
                          placeholder=""
                          onKeyUp={(e) => handleBackspaceAndEnter(e, index)}
                          onFocus={handleFocus}
                          ref={(reference) => (otpBoxReference.current[index] = reference)}
                          autoFocus={index === 0}
                        />
                      </div>
                    ))}
                  </div>
                )}

                {homeResponse?.code && (
                  <div className="d-flex justify-content-center mt-3 mb-3">
                    <Button
                      onClick={handleResendOtp}
                      disabled={resendCountdown > 0}
                      style={textStyle}
                      className="custom-button" // Add custom class for styling
                    >
                      {t("Resend OTP")} {resendCountdown ? `(${resendCountdown}s)` : ""}
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleVerifyOtp}
                      disabled={loading || !isOtpComplete}
                      className="login-button"
                      style={textStyle}
                    >
                      {loading ? `${t("Verifying OTP")}...` : t("Verify OTP")}
                    </Button>
                  </div>
                )}
              </Form>
            </div>
          </Col>
        </Row>
      </Modal>
    </>
  );
}

export default LoginModal;

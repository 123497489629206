import React, { useEffect } from "react";
import Footer from "../components/commonComponents/footer";
import BlogPage from "../components/home/Blogs";
import StaticPage from "../components/home/count";
// import Doctor from "../components/home/Doctors";
import Circle1 from '../../src/assets/images/new_home_page/Doctor 1.png'
import Circle2 from '../../src/assets/images/new_home_page/crm1.png'
import Circle3 from '../../src/assets/images/new_home_page/Herbal products_1.png'
import Livestock from "../components/home/FarmEquipmets";
import MarketPlace from "../components/home/marketPlace";
import Product from "../components/home/Bestseller";
import Testimonial from "../components/home/Testimonials";
import VideoPage from "../components/home/Videos";
import { useDispatch, useSelector } from "react-redux";
import homeActions from '../Redux/Home/action';
import Navbar from "../components/commonComponents/Navbar/Navbar";
import { PiChatText } from "react-icons/pi";
import { useTranslation } from 'react-i18next';
import { FaPhoneAlt } from "react-icons/fa";
import { DecryptFunction } from '../utils/cryptoFunction';

import PageLoader from "../common/pageLoader";
import Centercard from "../components/home/centercard";
import OurServices from "../components/home/ourServices";
import Appdownload from "../components/home/Appdownload";
import { LogEvent } from "../utils/firebase";
import cowsection from '../../src/assets/images/new_home_page/Instant treatment - cow.png';

import CommonDiseasesPage from "../components/home/CommonDiseases";
import MedicineSection from "../components/home/MedicineSection";

// import Header from "../components/commonComponents/header";


function Home() {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const textStyle = {
    fontSize: currentLang === 'tm' ? '12px' : '16px',
    lineHeight: currentLang === 'tm' ? '1.5' : '1.6', 
  };
  const { pageLoader } = useSelector(state => state.commonReducer);

  useEffect(() => {
    if (!sessionStorage.getItem('homepageViewed')) {
      LogEvent('Homepage_Viewed');
      sessionStorage.setItem('homepageViewed', true);
    };
  }, []);

  useEffect(() => {
    dispatch({ type: homeActions.GET_HOME_CONTENTS, payload: { params: { skip: "0", limit: "6", language_code: "1" } } });
    //eslint-disable-next-line
  }, []);
    
  return (
    <>
      {/* <Header /> */}
      {pageLoader &&
        <PageLoader />
      }
      <Navbar />
      <Centercard />
      <div className="new_homebackground">
      <div className="new_home-page-consultationandtreatment container">
        <div className="customtreatment-heading" style={{ padding: "0 10px;" }}>
          <strong>{t("Is your cow sick")}?</strong>
        </div>
        <div className="d-flex justify-content-around circular-tab-container">
          <div>
           
            <div className='message-circle'>
      <img src={Circle2} alt="Icon" className="message-image" />
    </div>
            <div className="circle-label" style={{...textStyle, color: '#232c63' }}>{t("24/7 Veterinary Service")}</div>
          </div>
          <div className="connector"></div>
          <div >
           
             <div className='message-circle'>
      <img src={Circle1} alt="Icon" className="message-image" />
    </div>
            <div className="circle-label" style={{ ...textStyle,color: '#232c63' }}>{t("Consult with the Doctor")}</div>
          </div>
          <div className="connector"></div>
          <div>
           
             <div className='message-circle'>
      <img src={Circle3} alt="Icon" className="message-image" />
    </div>
            <div className="circle-label" style={{...textStyle, color: '#232c63' }}>{t("Instant Remedies")}</div>
          </div>
        </div>
      </div>
      {/* <OurServices /> */}

      {/* <MarketPlace /> */}
      {/* <Livestock /> */}
      <div>
        </div>
      <img src={cowsection} className="img-responsive" alt="Cow section"></img>

        <div className="center-card-body d-flex align-items-center">
          <div className="position-relative w-100">
            <div className="new_home_page-content">
              <h5 className="card-title fw-semi-bold center-card-title new_home_page_custom-color">
                {t("Instant treatment solutions")}
                <br />
                {t("for more than 250 diseases")}
              </h5>
              <div >
                <button className='new_home_page_card_button d-flex justify-content-center'>
                  <div className="Team-Icon">
                    <FaPhoneAlt alt="Custom Icon" style={{color:'#232c63'}} />
                  </div>
                  <span onClick={() => {
                    const phoneNumber = DecryptFunction(sessionStorage.getItem('pilot_no'));
                    LogEvent('Floating_Call_Button_Clicked');
                    window.location.href = `tel:${phoneNumber}`;
                  }}>{t('Call Doctor')}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <CommonDiseasesPage />
      <MedicineSection />
      <Product />
      <Testimonial />
      <BlogPage />
      <Appdownload />
      <StaticPage />
      <Footer />
    </>
  );
}

export default Home;

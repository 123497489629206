import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import CattleDetailsModal from "../modal/cattleDetailsModal";
import Pagination from "../../common/pagination";
import noimg from '../../assets/images/noImage.jpg';

export default function List() {

    const { t } = useTranslation();

    const { listingdetails } = useSelector((state) => state.MarketPlaceReducer);

    const [activeTab, setActiveTab] = useState('All');
    const [fetchedData, setFetchedData] = useState([]);
    const [showCattleModal, setShowCattleModal] = useState({ isOpen: false, data: null });
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage] = useState(8);
    const [currentPage, setCurrentPage] = useState(0);

    useEffect(() => {
        if (listingdetails?.code === 200) {
            //eslint-disable-next-line
            setFetchedData(listingdetails?.data?.filter((val) => {
                if (activeTab === 'Sold') {
                    if (val?.listing?.listing_status === 4 || val?.listing?.listing_status === 5) {
                        return val;
                    }
                } else if (activeTab === 'Active') {
                    if (val?.listing?.listing_status === 3) {
                        return val;
                    }
                } else if (activeTab === 'Draft') {
                    if (val?.listing?.listing_status === 1) {
                        return val;
                    }
                } else {
                    return val
                }
            }));
        };
    }, [listingdetails, activeTab]);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = fetchedData?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(fetchedData?.length / itemsPerPage);

    const handlePageChange = (event) => {
        const newOffset = (event.selected * itemsPerPage) % fetchedData?.length;
        setItemOffset(newOffset);
        setCurrentPage(event?.selected);
        window.scrollTo({ top: 0 })
    };

    const handleShowModal = (id) => {
        setShowCattleModal({ isOpen: true, data: id });
    };

    return (
        <div className="mb-4">
            <div className="d-flex marketplace_seller_cattle_tabs">
                <div
                    className={`marketplace_seller_cattle_tab ${activeTab === 'All' && 'marketplace_seller_cattle_tab_active'}`}
                    onClick={() => setActiveTab('All')}
                >
                    {t("All")}
                </div>
                <div
                    className={`marketplace_seller_cattle_tab ${activeTab === 'Draft' && 'marketplace_seller_cattle_tab_active'}`}
                    onClick={() => setActiveTab('Draft')}
                >
                    {t("Draft")}
                </div>
                <div
                    className={`marketplace_seller_cattle_tab ${activeTab === 'Active' && 'marketplace_seller_cattle_tab_active'}`}
                    onClick={() => setActiveTab('Active')}
                >
                    {t("Active")}
                </div>
                <div
                    className={`marketplace_seller_cattle_tab ${activeTab === 'Sold' && 'marketplace_seller_cattle_tab_active'}`}
                    onClick={() => setActiveTab('Sold')}
                >
                    {t("Sold out")}
                </div>
            </div>
            {fetchedData?.length > 0 ?
                <Row className="mt-4 row-padding-remover">
                    {currentItems?.map((val, i) =>
                        <Col xl={3} lg={4} sm={6} xs={12} key={i} className="mb-4">
                            <div className="marketplace_seller_catte_list_col_card">
                                <div className="marketplace_seller_catte_list_col_title mb-3 cursor-pointer" onClick={() => handleShowModal(val?.listing?.id)}>{val?.listing?.title}</div>
                                <div className="marketplace_seller_catte_list_col_rate mb-3">₹{val?.listing?.rate}</div>
                                <img src={val?.cattle?.image_front_url || noimg} alt="" className="marketplace_seller_cattle_img mb-2" />
                                <div className={`${val?.listing?.listing_status === 1 ? 'marketplace_seller_cattle_Draft_btn' : (val?.listing?.listing_status === 2 || val?.listing?.listing_status === 3) ? "marketplace_seller_cattle_active_btn" : "marketplace_seller_cattle_sold_btn"}`}>
                                    {val?.listing?.listing_status === 1 ? 'Draft' : (val?.listing?.listing_status === 2 || val?.listing?.listing_status === 3) ? "Active" : (val?.listing?.listing_status === 4 || val?.listing?.listing_status === 5) ? "Sold" : ""}
                                </div>
                            </div>
                        </Col>
                    )}
                    {pageCount > 1 && currentItems?.length > 1 &&
                        <div className="mt-4">
                            <Pagination pageCount={pageCount} onPageChange={handlePageChange} currentPage={currentPage} />
                        </div>
                    }
                </Row> :
                <div className="no-data-found-div-cls">{t("No Data Found")}</div>
            }
            <CattleDetailsModal
                showModal={showCattleModal?.isOpen}
                closeModal={() => setShowCattleModal({ isOpen: false, data: null })}
                cattleId={showCattleModal?.data}
                page={'view'}
            />
        </div>
    )
};
// import React from "react";
// import Slider from 'react-slick';
// import "slick-carousel/slick/slick-theme.css";
// import "slick-carousel/slick/slick.css";
// import videoplay from '../../assets/images/videoplay.png';

// export default function ProductImages(props) {

//     const { images ,onClick} = props;


//     const settings = {
//         dots: true,
//         infinite: true,
//         arrows: false,
//         autoplay: true,
//         autoplaySpeed: 2000,
//         speed: 500,
//         slidesToShow: 1,
//         slidesToScroll: 1,
//     };

//     return (
//         <Slider {...settings}>
//             {images?.map((val, i) =>
//                 <a key={i}  target="_blank" rel="noopener noreferrer" onClick={() => onClick(val)}>
//                     {val?.type === 'video' ?
//                         <img src={val.url} alt="" className="product-details-img" /> :
//                         <img src={val?.url} alt="" className="product-details-img" />
//                     }
//                 </a>
//             )}
//         </Slider>
//     )
// }


import React from "react";
import Slider from 'react-slick';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import videoplay from '../../assets/images/videoplay.png';

export default function ProductImages(props) {
    const { images, onClick } = props;

    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    return (
            <Slider {...settings}>
                {images?.map((val, i) =>
                    <a key={i} target="_blank" rel="noopener noreferrer" onClick={() => onClick(val)}>
                        {val?.type === 'video' ? (
                            <video controls className="product-details-video">
                                <source src={val?.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={val?.url} alt="" className="product-details-img" />
                        )}
                    </a>
                )}
            </Slider>
    )
}
